import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchApprovedProducers } from "../../redux/slices/producersSlice";
import { formatCreatedAt } from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
import { IoEyeOutline } from "react-icons/io5";

const ApprovedProducers = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApprovedProducers());
  }, [dispatch]);

  const approvedProducers = useSelector((state) => state.producer?.approvedProducers);

  const reversedProducers = approvedProducers?.slice().reverse();

  // Filter drivers based on search term and vendor category
  const filteredProducers = reversedProducers?.filter(
    (user) =>
      search.trim() === "" // Check if the search term is empty
        ? true // If empty, include all drivers
        : user?.name?.toLowerCase().includes(search.toLowerCase()) || // Perform case-insensitive comparison
          user?.sector?.toLowerCase().includes(search.toLowerCase()) // Perform case-insensitive comparison for vendor category
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentProducers = filteredProducers.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredProducers?.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.producer?.status);
  const error = useSelector((state) => state.producer?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Approved Producers ({approvedProducers?.length})
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Approved Producers</p>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Approved Producers List
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>

                <th className="px-4 py-4 text-left">Name</th>

                <th className="px-4 py-4 text-center whitespace-nowrap">
                  Ref Code
                </th>

                <th className="px-4 py-4 text-center">Country</th>
                <th className="px-4 py-4 text-center">State</th>
                <th className="px-4 py-4 text-center">City</th>
                <th className="px-4 py-4 text-center">Sector</th>
                <th className="px-4 py-4 text-center">Role</th>
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Last Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentProducers.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentProducers?.map((user, index) => (
                  <tr key={user.id}>
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4">
                      <Link to={`/dashboard/producer/view-producer/${user.id}`}>
                        <div className="flex flex-col text-left gap-2">
                          <p className=" text-blue-600 text-base font-semibold">
                            {user.name}
                          </p>

                          <p className=" text-gray-500">{user.email}</p>
                          <p className=" font-bold">{user.phone}</p>
                        </div>
                      </Link>
                    </td>

                    <td className="px-4 py-4 text-center">{user.ref_code}</td>
                    <td className="px-4 py-4 text-center">
                      {getCountryNameById(user.country)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {getStateNameById(user.state)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {getCityNameById(user.city)}
                    </td>
                    <td className="px-4 py-4 text-center">{user.sector}</td>
                    <td className="px-4 py-4 text-center">{user.role}</td>

                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.created_at)}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <Link to={`/dashboard/producer/view-producer/${user.id}`}>
                        <div className="flex gap-4 justify-center items-center">
                          <IoEyeOutline className="text-base text-[#667085]" />
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {approvedProducers?.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedProducers;
