import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteVendorSubscription, fetchVendorSubscriptions } from "../../../redux/slices/vendorsSlice";
import { FaRegSquareCheck } from "react-icons/fa6";

export default function DeleteVendorSub({ vendorId }) {
  const [showModal, setShowModal] = useState(false);
  const status = useSelector((state) => state.vendor?.status);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteVendorSubscription(vendorId));
      await dispatch(fetchVendorSubscriptions());
      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete vendor subscription.");
    }
    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex font-medium"
      >
        <FaRegSquareCheck className="text-red-600 text-lg" />
      </button>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 max-w-lg w-full">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="text-center">
                  <h1 className="text-2xl text-gray-800 font-medium mb-4">
                    Delete Vendor Subscription
                  </h1>
                  <p className="text-gray-600">Are you sure you want to delete this vendor subscription? This action cannot be undone.</p>
                </div>
                <div className="flex mt-6 gap-4 justify-center">
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-sm text-gray-600 font-semibold bg-gray-200 py-2 px-4 rounded-lg"
                  >
                    Cancel
                  </button>
                  <button
                    className="flex gap-1 rounded-lg items-center bg-red-600 text-white py-2 px-4"
                    disabled={status === "loading"}
                    onClick={handleDelete}
                  >
                    {status === "loading" ? (
                      <>
                        <FaSpinner className="animate-spin" />
                        <p className="text-sm font-semibold whitespace-nowrap">
                          Deleting...
                        </p>
                      </>
                    ) : (
                      <p className="text-sm font-semibold whitespace-nowrap">
                        Delete
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
