import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchProducers = createAsyncThunk(
  "producer/fetchProducers",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/producers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPendingProducers = createAsyncThunk(
  "producer/fetchPendingProducers",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/pending-producers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchApprovedProducers = createAsyncThunk(
  "producer/fetchApprovedProducers",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/approved-producers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSectors = createAsyncThunk(
  "producer/fetchSectors",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/sectors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createSector = createAsyncThunk(
  "producer/createSector",
  async (sectorData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create-sector", sectorData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchApprovedProducts = createAsyncThunk(
  "producer/fetchApprovedProducts",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/approved-products", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPendingApprovals = createAsyncThunk(
  "producer/fetchPendingApprovals",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/pending-approval", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveProduct = createAsyncThunk(
  "producer/approveProduct",
  async (productId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        `/approve-product/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const declineProduct = createAsyncThunk(
  "producer/declineProduct",
  async ({ id, reason }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        `/decline-product/${id}`,
        { reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchActiveSubscribers = createAsyncThunk(
  "producer/fetchActiveSubscribers",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/active-subscribers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProducerSubscriptions = createAsyncThunk(
  "producer/fetchProducerSubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/producer/get-subs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createProducerSubcription = createAsyncThunk(
  "producer/createProducerSubcription",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/producer/create-sub", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProducerSubscription = createAsyncThunk(
  "vendor/deleteProducerSubscription",
  async (Id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.delete(`/producer/delete-sub/${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to delete subscription");
    }
  }
);

export const fetchFreeSubscribers = createAsyncThunk(
  "producer/fetchFreeSubscribers",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/free-subscribers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProducerById = createAsyncThunk(
  "producer/fetchProducerById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(`/view-producer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const producersSlice = createSlice({
  name: "producer",
  initialState: {
    producers: [],
    sectors: [],
    pendingProducers: [],
    approvedProducers: [],
    approvedProducts: [],
    pendingApprovals: [],
    activeSubscribers: [],
    producerSubscriptions: [],
    freeSubscribers: [],
    producerDetails: null,
    status: "idle",
    error: null,
    declineProductStatus: 'idle',
    declineProductError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProducers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.producers = action.payload.data;
      })
      .addCase(fetchProducers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createProducerSubcription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProducerSubcription.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.producerSubscriptions.unshift(action.payload);
      })
      .addCase(createProducerSubcription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchProducerSubscriptions.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProducerSubscriptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.producerSubscriptions = action.payload.data;
      })
      .addCase(fetchProducerSubscriptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPendingProducers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPendingProducers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pendingProducers = action.payload.data;
      })
      .addCase(fetchPendingProducers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchApprovedProducers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchApprovedProducers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.approvedProducers = action.payload.data;
      })
      .addCase(fetchApprovedProducers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSectors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSectors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sectors = action.payload.data;
      })
      .addCase(fetchSectors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createSector.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSector.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sectors.unshift(action.payload);
      })
      .addCase(createSector.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchApprovedProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApprovedProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.approvedProducts = action.payload;
      })
      .addCase(fetchApprovedProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPendingApprovals.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPendingApprovals.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pendingApprovals = action.payload;
      })
      .addCase(fetchPendingApprovals.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(approveProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(approveProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchActiveSubscribers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchActiveSubscribers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activeSubscribers = action.payload.data;
      })
      .addCase(fetchActiveSubscribers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchFreeSubscribers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchFreeSubscribers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.freeSubscribers = action.payload.data;
      })
      .addCase(fetchFreeSubscribers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchProducerById.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProducerById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.producerDetails = action.payload.data;
      })
      .addCase(fetchProducerById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(declineProduct.pending, (state) => {
        state.declineProductStatus = 'loading';
      })
      .addCase(declineProduct.fulfilled, (state) => {
        state.declineProductStatus = 'succeeded';
      })
      .addCase(declineProduct.rejected, (state, action) => {
        state.declineProductStatus = 'failed';
        state.declineProductError = action.payload;
      });
  },
});

export default producersSlice.reducer;
