import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addRegistrationFee } from "../../redux/slices/feeSettingSlice";

const RegistrationFee = () => {
  const [formData, setFormData] = useState({
    vendor: "",
    producer: "",
  });

  const dispatch = useDispatch();

  const registration_fee = useSelector(
    (state) => state.feeSetting?.feeSettings?.registration_fee
  );


  useEffect(() => {
    if (registration_fee) {
      setFormData({
        vendor: registration_fee.vendor || "",
        producer: registration_fee.producer || "",
      });
    }
  }, [registration_fee]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const status = useSelector(
    (state) => state.feeSetting?.registrationFeeStatus
  );

  const handleSubmit = async () => {
    if (!formData.vendor || !formData.producer) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    try {
      await dispatch(addRegistrationFee(formData));
      toast.success("Registration fee successfully set");
    } catch (error) {}
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Registration Fee
          </h1>

          <div className="flex mb-4 justify-start items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Fee Setting
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Registration Fee
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4"></div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
          <div className="relative overflow-x-auto">
            <div className="mt-4">
              <div className=" bg-gray-50 text-center">
                <p className="text-2xl font-bold">Registration Fee Setting</p>
              </div>
              <div className="flex mt-4 items-center justify-center px-8 py-4">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row justify-between items-center gap-4">
                    <label
                      htmlFor="vendor_fee"
                      className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                    >
                      Vendor Fee
                    </label>
                    <div className="flex justify-end">
                      <input
                        name="vendor"
                        id="vendor"
                        type="number"
                        value={formData.vendor}
                        onChange={handleInputChange}
                        placeholder="Type here..."
                        className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-4">
                    <label
                      htmlFor="producer_fee"
                      className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                    >
                      Producer Fee
                    </label>
                    <div className="flex justify-end">
                      <input
                        name="producer"
                        id="producer"
                        type="number"
                        value={formData.producer}
                        onChange={handleInputChange}
                        placeholder="Type here..."
                        className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="flex gap-1 mt-4 rounded-lg items-center justify-center bg-blue-600 text-white py-2 px-4"
                      disabled={status === "loading"}
                      onClick={handleSubmit}
                    >
                      {status === "loading" ? (
                        <>
                          <FaSpinner className="animate-spin" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Submitting ...
                          </p>
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationFee;
