import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./pages/auth/Login";
import Layout from "./shared/Layout";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/users/Users";
import Shoppers from "./pages/shoppers/Shoppers";
import Investment from "./pages/investment/Investment";
import AdminList from "./pages/admin/AdminList";
import Shopping from "./pages/stores/Shopping";
import ProductsList from "./pages/stores/ProductsList";
import OrderDetail from "./pages/orders/OrderDetail";
import OrderOngoing from "./pages/orders/OrderOngoing";

import Markets from "./pages/stores/Markets";
import Stores from "./pages/stores/Stores";
import AddMarket from "./components/shopping/AddMarket";
import Laundry from "./pages/laundry/Laundry";
import AdminDetail from "./pages/admin/AdminDetail";
import Admin from "./pages/admin/Admin";
import Orders from "./pages/orders/Orders";
import UsersList from "./pages/users/UsersList";
import NewAdmin from "./pages/admin/NewAdmin";
import InvestmentList from "./pages/investment/InvestmentList";
import OTP from "./pages/auth/OTP";
import UserDetail from "./pages/users/UserDetail";
import InvestorList from "./pages/investment/InvestorList";
import AddInvestment from "./pages/investment/AddInvestment";
import Transport from "./pages/transport/Transport";
import TransportList from "./pages/transport/TransportList";
import Location from "./pages/location/Location";
import Countries from "./pages/location/Countries";
import States from "./pages/location/States";
import Province from "./pages/location/Province";
import LaundrySub from "./pages/laundry/LaundrySub";

import LaundryCart from "./pages/laundry/LaundryCart";
import LaundryCancelled from "./pages/laundry/LaundryCancelled";
import LaundryCompleted from "./pages/laundry/LaundryCompleted";
import OrderPending from "./pages/orders/OrderPending";
import OrderCompleted from "./pages/orders/OrderCompleted";
import OrderCancelled from "./pages/orders/OrderCancelled";
import OrderReported from "./pages/orders/OrderReported";
import LaundryDisputed from "./pages/laundry/LaundryDisputed";
import ProductSub from "./pages/stores/ProductSub";
import ProjectList from "./pages/investment/ProjectList";
import IsusuList from "./pages/investment/IsusuList";
import IsusuDetails from "./pages/investment/IsusuDetails";
import MarketDetail from "./pages/stores/MarketDetail";
import StoreDetail from "./pages/stores/StoreDetail";
import AddStore from "./components/shopping/AddStore";
import LaundryStore from "./pages/laundry/LaundryStore";
import LaundryStoreDetail from "./pages/laundry/LaundryStoreDetail";
import FastFood from "./pages/stores/FastFood";
import Pharmacy from "./pages/stores/Pharmacy";
import PharmacyDetail from "./pages/stores/PharmacyDetail";
import FastFoodDetail from "./pages/stores/FastFoodDetail";
import AddFastFood from "./components/shopping/AddFastFood";
import AddPharmacy from "./components/shopping/AddPharmacy";
import Fuel from "./pages/fuel/Fuel";
import FuelList from "./pages/fuel/FuelList";
import OrderList from "./pages/orders/OrderList";
import ShopperList from "./pages/shoppers/ShopperList";
import DriverList from "./pages/drivers/DriverList";
import Drivers from "./pages/drivers/Drivers";
import OrderCompletedDetail from "./pages/orders/OrderCompletedDetail";
import Categories from "./pages/stores/Categories";
import OrderOngoingDetail from "./pages/orders/OrderOngoingDetail";
import OrderPendingDetail from "./pages/orders/OrderPendingDetail";
import OrderCancelledDetail from "./pages/orders/OrderCancelledDetail";
import ProductDetail from "./pages/stores/ProductDetail";
import DriverDetail from "./pages/drivers/DriverDetail";
import ShopperDetail from "./pages/shoppers/ShopperDetail";
import OrderReportedDetail from "./pages/orders/OrderReportedDetail";
import ShopperWithdrawal from "./pages/shoppers/ShopperWithdrawal";
import ShopperRemit from "./pages/shoppers/ShopperRemit";
import DriverWithdrawal from "./pages/drivers/DriverWithdrawal";
import DriverRemit from "./pages/drivers/DriverRemit";
import LaundryPendingDetail from "./pages/laundry/LaundryPendingDetail";
import LaundryCancelledDetail from "./pages/laundry/LaundryCancelledDetail";
import LaundryDisputedDetail from "./pages/laundry/LaundryDisputedDetail";
import LaundryCompletedDetail from "./pages/laundry/LaundryCompletedDetail";
import OrdersPlaced from "./pages/tracks/OrdersPlaced";

import ItemsReceived from "./pages/tracks/ItemsReceived";
import OngoingOrders from "./pages/tracks/OngoingOrders";
import ReadyforDelivered from "./pages/tracks/ReadyforDelivered";
import ItemsDelivered from "./pages/tracks/ItemsDelivered";
import Tracks from "./pages/tracks/Tracks";
import Laundrylist from "./pages/laundry/Laundrylist";
import LaundryOrdersDetail from "./pages/laundry/LaundryOrdersDetail";
import ItemsDeliveredDetail from "./pages/tracks/ItemsDeliveredDetail";
import ItemsReceivedDetail from "./pages/tracks/ItemsReceivedDetail";
import ReadyforDeliveryDetail from "./pages/tracks/ReadyforDeliveryDetail";
import OngoingOrderDetail from "./pages/tracks/OngoingOrderDetail";
import OrderPlacedDetail from "./pages/tracks/OrderPlacedDetail";
import DriverPayment from "./pages/drivers/DriverPayment";
import ShopperPayment from "./pages/shoppers/ShopperPayment";
import FeeSetting from "./pages/feeSettting/FeeSetting";
import LaundryFeeSetting from "./pages/feeSettting/LaundryFeeSetting";
import ShoppingFeeSetting from "./pages/feeSettting/ShoppingFeeSetting";
import TranscationFeeSetting from "./pages/feeSettting/TranscationFeeSetting";
import DriverCompletedPayment from "./pages/drivers/DriverCompletedPayment";
import ShopperCompletedPayment from "./pages/shoppers/ShopperCompletedPayment";
import InvestmentFeeSetting from "./pages/feeSettting/InvestmentFeeSetting";
import DeliveryOrders from "./pages/orders/OrderDeliveredOrders";
import PendingDelivery from "./pages/orders/OrderPendingDelivery";
import OrderCart from "./pages/orders/OrderCart";
import OrderPendingDeliveryDetail from "./pages/orders/OrderPendingDeliveryDetail";
import OrderDeliveredOrderDetail from "./pages/orders/OrderDeliveredOrderDetail";
import OrderCartDetail from "./pages/orders/OrderCartDetail";
import AdminRole from "./pages/admin/AdminRoles";
import MonitorOrder from "./pages/monitorOrder/MonitorOrder";
import MonitorOrders from "./pages/monitorOrder/MonitorOrders";
import UserTopupWallet from "./pages/users/UserTopupWallet";
import UserPromotion from "./pages/users/UserPromotion";
import UserTopupWalletDetail from "./pages/users/UserTopupWalletDetail";
import UserPromotionsDetail from "./pages/users/UserPromotionsDetail";
import ShopperPromotion from "./pages/shoppers/ShopperPromotion";
import ShopperPromotionDetail from "./pages/shoppers/ShopperPromotionDetail";
import DriverPromotion from "./pages/drivers/DriverPromotion";
import DriverPromotionDetail from "./pages/drivers/DriverPromotionDetail";
import UsersDeletionRequest from "./pages/users/UserDeletionRequest";
import ShopperDeletionRequest from "./pages/shoppers/ShopperDeletionRequest";
import DriverDeletionRequest from "./pages/drivers/DriverDeletionRequest";
import UsersDeletionRequestDetail from "./pages/users/UserDeletionRequestDetail";
import TrendingProducts from "./pages/stores/TrendingProducts";
import TrendingProductDetail from "./pages/stores/TrendingProductDetail";
import ShopperDeletionRequestDetail from "./pages/shoppers/ShopperDeletionRequestDetail";
import DriverDeletionRequestDetail from "./pages/drivers/DriverDeletionRequestDetail";
import DriverPushOrders from "./pages/drivers/DriverPushOrders";
import StoreLocator from "./pages/shoppers/StoreLocator";
import LaundryReported from "./pages/laundry/LaundryReported";
import LaundryReportedDetail from "./pages/laundry/LaundryReportedDetail";
import AdminFunctions from "./pages/admin/AdminFunctions";
import OrderExpress from "./pages/orders/OrderExpress";
import UserShoppingCoupon from "./pages/users/UserShoppingCoupon";
import UserLaundryCoupon from "./pages/users/UserLaundryCoupon";
import OrderFutureDeliveries from "./pages/orders/OrderFutureDeliveries";
import PendingPaymentLaundry from "./pages/laundry/PendingPaymentLaundry";
import LaundryDropOff from "./pages/laundry/LaundryDropOff";
import UserIsusuCoupon from "./pages/users/UserIsusuCoupon";
import IsusuApprovalRequest from "./pages/investment/IsusuApprovalRequest";
import OrderPayonDelivery from "./pages/orders/OrderPayonDelivery";
import PendingPaymentLaundryDetail from "./pages/laundry/PendingPaymentLaundryDetail";
import LaundryDropOffDetail from "./pages/laundry/LaundryDropOffDetail";
import OrderFutureDeliveriesDetail from "./pages/orders/OrderFutureDeliveriesDetail";
import IsusuDefaulter from "./pages/investment/IsusuDefaulter";
import IsusuReceivers from "./pages/investment/IsusuReceivers";
import OrderExpressDetail from "./pages/orders/OrderExpressDetail";
import Tipper from "./pages/users/Tipper";
import OrderPayonDeliveryDetail from "./pages/orders/OrderPayonDeliveryDetail";
import DriverPushOrderDetail from "./pages/drivers/DriverPushOrderDetail";
import IsusuApprovalUserDetail from "./pages/investment/IsusuApprovalUserDetail";
import UserCouponHistory from "./pages/users/UserCouponHistory";
import UserPromotionHistory from "./pages/users/UserPromotionHistory";
import TipperOrderDetails from "./pages/users/TipperOrderDetails";
import ShopperSellerInfo from "./pages/shoppers/ShopperSellerInfo";
import DailyROI from "./pages/users/DailyROI";
import BankPayment from "./pages/bankPayment/BankPayment";
import PersonalizeShopping from "./pages/bankPayment/PersonalizeShopping";
import WalletFunding from "./pages/bankPayment/WalletFunding";
import LaundryPay from "./pages/bankPayment/LaundryPay";
import CartShopping from "./pages/bankPayment/CartShopping";
import CartShoppingOrderDetail from "./pages/bankPayment/CartShoppingOrderDetail";
import PersonalizeShoppingOrderDetail from "./pages/bankPayment/PersonalizeShoppingOrderDetail";
import FuelGasOrderDetail from "./pages/bankPayment/FuelGasOrderDetail";
import WalletFundingOrderDetail from "./pages/bankPayment/WalletFundingOrderDetail";
import LaundryPayOrdersDetail from "./pages/bankPayment/LaundryPayOrderDetail";
import FuelGas from "./pages/bankPayment/FuelGas";
import CompletedBankTransfer from "./pages/bankPayment/CompletedBankTransfer";
import UserTranscations from "./pages/users/UserTranscations";
import UserTranscationsDetail from "./pages/users/UserTranscationsDetail";
import UserBirthdays from "./pages/users/UserBirthdays";
import UserEmailMarketing from "./pages/users/UserEmailMarketing";
import UserEmailMarketingDetail from "./pages/users/UserEmailMarketingDetail";
import Legal from "./pages/legal/Legal";
import TermandCondition from "./pages/legal/TermandCondition";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import VTUTranscations from "./pages/users/VTUTranscations";
import VTUTranscationsDetail from "./pages/users/VTUTranscationsDetail";
import PriceFeedback from "./pages/stores/PriceFeedback";
import UserWalletDeduction from "./pages/users/UserWalletDeduction";
import RequestedTransfer from "./pages/bankPayment/RequestedTransfer";
import UserWalletDeductionDetail from "./pages/users/UserWalletDeductionDetail";
import OrderPayonDeliveryUserDetail from "./pages/orders/OrderPayonDeliveryUserDetail";
import RequestedTransferUserDetail from "./pages/bankPayment/RequestedTransferUserDetail";
import OrderPlacedUser from "./pages/tracks/OrderPlacedUser";
import Producer from "./pages/producers/Producer";
import Producers from "./pages/producers/Producers";
import Sectors from "./pages/producers/Sectors";
import Vendor from "./pages/vendors/Vendor";
import Vendors from "./pages/vendors/Vendors";
import ApprovedTradefairs from "./pages/tradefairs/ApprovedTradefairs";
import PendingTradefairs from "./pages/tradefairs/PendingTradefairs";
import Tradefair from "./pages/tradefairs/Tradefair";
import TradefairDetails from "./pages/tradefairs/TradefairDetails";
import VendorDetail from "./pages/vendors/VendorDetail";
import PendingProduct from "./pages/vendors/PendingProduct";
import ApprovedProducts from "./pages/vendors/ApprovedProducts";
import PendingProductApproval from "./pages/producers/PendingProductApproval";
import ApprovedProducerProduct from "./pages/producers/ApprovedProducerProduct";
import ActiveSubscribers from "./pages/producers/ActiveSubscribers";
import AdminProducerSubscriptions from "./pages/producers/ProducerSubscriptions";
import FreeSubscribers from "./pages/producers/FreeSubscribers";
import ProducerDetails from "./pages/producers/ProducerDetail";
import RegistrationFee from "./pages/feeSettting/RegistrationFee";
import UserAutoEmailMessage from "./pages/users/UserAutoEmailMessage";
import PendingTradefairDetails from "./pages/tradefairs/PendingTradefairDetails";
import DeclineVendorProducts from "./pages/vendors/DeclineVendorProducts";
import DeclineProducerProduct from "./pages/producers/DeclineProducerProduct";
import VendorSubscriptions from "./pages/vendors/VendorSubscriptions";
import PendingProducers from "./pages/producers/PendingProducers";
import ApprovedProducers from "./pages/producers/ApprovedProducers";
import ApprovedVendors from "./pages/vendors/ApprovedVendors";
import PendingVendors from "./pages/vendors/PendingVendors";
import UserReferalRankings from "./pages/users/UserReferalRankings";
import UserReferalRankingDetail from "./pages/users/UserReferalRankingDetail";
import CancelledTradefairs from "./pages/tradefairs/CancelledTradefairs";
import CartShoppingUserDetail from "./pages/bankPayment/CartShoppingUserDetail";
import LaundryExpress from "./pages/tracks/LaundryExpress";
import LaundryExpressDetail from "./pages/tracks/LaundryExpressDetail";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/dashboard" element={<Layout />}>
        <Route index element={<Dashboard />} />

        <Route path="orders" element={<Orders />}>
          <Route index element={<OrderList />} />
          <Route path="order-list" element={<OrderList />} />
          <Route path="orders-detail/:id" element={<OrderDetail />} />
          <Route path="ongoing-orders" element={<OrderOngoing />} />
          <Route path="carts-orders" element={<OrderCart />} />
          <Route path="carts-orders/:id" element={<OrderCartDetail />} />
          <Route path="pending-deliveries" element={<PendingDelivery />} />
          <Route path="delivered-orders" element={<DeliveryOrders />} />
          <Route path="pay-on-delivery" element={<OrderPayonDelivery />} />
          <Route
            path="pay-on-delivery/:id"
            element={<OrderPayonDeliveryDetail />}
          />
          <Route
            path="pay-on-delivery/user/:id"
            element={<OrderPayonDeliveryUserDetail />}
          />

          <Route path="future-deliveries" element={<OrderFutureDeliveries />} />
          <Route
            path="future-deliveries/:id"
            element={<OrderFutureDeliveriesDetail />}
          />
          <Route
            path="ongoing-order-detail/:id"
            element={<OrderOngoingDetail />}
          />
          <Route
            path="pending-delivery-detail/:id"
            element={<OrderPendingDeliveryDetail />}
          />
          <Route
            path="delivered-order-detail/:id"
            element={<OrderDeliveredOrderDetail />}
          />
          <Route path="cart-order-detail/:id" element={<OrderCartDetail />} />
          <Route path="pending-orders" element={<OrderPending />} />
          <Route path="express-orders" element={<OrderExpress />} />
          <Route
            path="express-orders-detail/:id"
            element={<OrderExpressDetail />}
          />
          <Route
            path="pending-orders-detail/:id"
            element={<OrderPendingDetail />}
          />
          <Route path="cancelled-orders" element={<OrderCancelled />} />
          <Route
            path="cancelled-orders-detail/:id"
            element={<OrderCancelledDetail />}
          />
          <Route path="completed-orders" element={<OrderCompleted />} />
          <Route
            path="completed-order-detail/:id"
            element={<OrderCompletedDetail />}
          />
          <Route path="reported-orders" element={<OrderReported />} />
          <Route path="reported-detail/:id" element={<OrderReportedDetail />} />
          <Route path="shopping-subscription" element={<ProductSub />} />
        </Route>

        <Route path="shopping" element={<Shopping />}>
          <Route index element={<ProductsList />} />
          <Route path="products" element={<ProductsList />} />

          <Route path="markets" element={<Markets />} />
          <Route path="markets/:id" element={<MarketDetail />} />
          <Route path="stores" element={<Stores />} />
          <Route path="categories" element={<Categories />} />
          <Route path="products" element={<ProductsList />} />
          <Route path="trending-products" element={<TrendingProducts />} />
          <Route path="product-detail/:id" element={<ProductDetail />} />
          <Route path="price-feedback" element={<PriceFeedback />} />
          <Route
            path="trending-product-detail/:id"
            element={<TrendingProductDetail />}
          />
          <Route path="fast-food" element={<FastFood />} />
          <Route path="pharmacy" element={<Pharmacy />} />
          <Route path="stores/:id" element={<StoreDetail />} />
          <Route path="pharmacy/:id" element={<PharmacyDetail />} />
          <Route path="fast-food/:id" element={<FastFoodDetail />} />
          <Route path="add-market" element={<AddMarket />} />
          <Route path="add-store" element={<AddStore />} />
          <Route path="add-fastfood" element={<AddFastFood />} />
          <Route path="add-pharmacy" element={<AddPharmacy />} />
        </Route>

        <Route path="producer" element={<Producer />}>
          <Route index element={<Producers />} />
          <Route path="producers" element={<Producers />} />
          <Route path="pending-producers" element={<PendingProducers />} />
          <Route path="approved-producers" element={<ApprovedProducers />} />
          <Route path="view-producer/:id" element={<ProducerDetails />} />
          <Route path="sectors" element={<Sectors />} />
          <Route path="pending-approvals" element={<PendingProductApproval />} />
          <Route path="decline-products" element={<DeclineProducerProduct />} />
          <Route path="approved-products" element={<ApprovedProducerProduct />} />
          <Route path="active-subscribers" element={<ActiveSubscribers />} />
          <Route path="producer-subscriptions" element={<AdminProducerSubscriptions />} />
          <Route path="free-subscribers" element={<FreeSubscribers />} />
          
        </Route>

        <Route path="vendor" element={<Vendor />}>
          <Route index element={<Vendors />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="pending-vendors" element={<PendingVendors />} />
          <Route path="approved-vendors" element={<ApprovedVendors />} />
          
          <Route path="view-vendor/:id" element={<VendorDetail />} />
          <Route path="pending-products" element={<PendingProduct />} />
          <Route path="decline-products" element={<DeclineVendorProducts />} />
          <Route path="approved-products" element={<ApprovedProducts />} />
          <Route path="vendor-subscriptions" element={<VendorSubscriptions />} />
          
        </Route>

        <Route path="tradefair" element={<Tradefair />}>
          <Route index element={<ApprovedTradefairs />} />
          <Route path="approved-tradefair" element={<ApprovedTradefairs />} />
          <Route path="cancelled-tradefair" element={<CancelledTradefairs />} />
          <Route path="view-tradefair/:id" element={<TradefairDetails />} />
          <Route path="view-pending-tradefair/:id" element={<PendingTradefairDetails />} />
          <Route path="pending-tradefair" element={<PendingTradefairs />} />
        </Route>

        <Route path="laundry" element={<Laundry />}>
          <Route index element={<Laundrylist />} />

          <Route path="laundry-cart/:id" element={<LaundryPendingDetail />} />
          <Route
            path="laundry-cancelled-detail/:id"
            element={<LaundryCancelledDetail />}
          />
          <Route
            path="laundry-disputed-detail/:id"
            element={<LaundryDisputedDetail />}
          />
          <Route
            path="laundry-completed-detail/:id"
            element={<LaundryCompletedDetail />}
          />
          <Route
            path="laundry-orders-detail/:id"
            element={<LaundryOrdersDetail />}
          />
          <Route path="laundry-list" element={<Laundrylist />} />
          <Route path="laundry-cart" element={<LaundryCart />} />
          <Route path="cancelled-orders" element={<LaundryCancelled />} />
          <Route path="laundry-reported-orders" element={<LaundryReported />} />
          <Route
            path="reported-orders-detail/:id"
            element={<LaundryReportedDetail />}
          />
          <Route path="completed-orders" element={<LaundryCompleted />} />

          <Route path="laundry-store" element={<LaundryStore />} />
          <Route path="laundry-store/:id" element={<LaundryStoreDetail />} />
          <Route path="laundry-subscription" element={<LaundrySub />} />
          <Route path="laundry-drop-off" element={<LaundryDropOff />} />
          <Route
            path="laundry-drop-off/:id"
            element={<LaundryDropOffDetail />}
          />
        
          <Route
            path="pending-payments-laundry"
            element={<PendingPaymentLaundry />}
          />
          <Route
            path="pending-payments-laundry/:id"
            element={<PendingPaymentLaundryDetail />}
          />
        </Route>

        <Route path="fee-setting" element={<FeeSetting />}>
          <Route path="laundy-fee-setting" element={<LaundryFeeSetting />} />
          <Route path="shopping-fee-setting" element={<ShoppingFeeSetting />} />
          <Route
            path="invesmtent-fee-setting"
            element={<InvestmentFeeSetting />}
          />
          <Route
            path="transcation-fee-setting"
            element={<TranscationFeeSetting />}
          />
          <Route path="registration-fee" element={<RegistrationFee />} />
        </Route>

        <Route path="tracks" element={<Tracks />}>
          <Route index element={<OrdersPlaced />} />
          <Route path="orders-placed" element={<OrdersPlaced />} />
          <Route path="laundry-express-orders" element={<LaundryExpress />} />
          <Route
            path="laundry-express-detail/:id"
            element={<LaundryExpressDetail />}
          />
          <Route path="orders-placed-user/:id" element={<OrderPlacedUser />} />
          <Route path="items-received" element={<ItemsReceived />} />
          <Route path="ongoing-orders" element={<OngoingOrders />} />
          <Route path="ready-for-delivery" element={<ReadyforDelivered />} />
          <Route path="items-delivered" element={<ItemsDelivered />} />
          <Route path="disputed-orders" element={<LaundryDisputed />} />
          <Route
            path="items-delivered-detail/:id"
            element={<ItemsDeliveredDetail />}
          />
          <Route
            path="items-received-detail/:id"
            element={<ItemsReceivedDetail />}
          />
          <Route
            path="ready-for-delivery-detail/:id"
            element={<ReadyforDeliveryDetail />}
          />
          <Route
            path="ongoing-order-detail/:id"
            element={<OngoingOrderDetail />}
          />
          <Route
            path="order-placed-detail/:id"
            element={<OrderPlacedDetail />}
          />
        </Route>

        <Route path="shoppers" element={<Shoppers />}>
          <Route index element={<ShopperList />} />
          <Route path="shopper-list" element={<ShopperList />} />
          <Route path="withdrawal" element={<ShopperWithdrawal />} />
          <Route path="remit" element={<ShopperRemit />} />
          <Route path="payment" element={<ShopperPayment />} />
          <Route path="promotion" element={<ShopperPromotion />} />
          <Route path="store-locator" element={<StoreLocator />} />
          <Route path="seller-info" element={<ShopperSellerInfo />} />
          <Route path="deletion-request" element={<ShopperDeletionRequest />} />

          <Route
            path="deletion-request/:id"
            element={<ShopperDeletionRequestDetail />}
          />
          <Route
            path="completed-payment"
            element={<ShopperCompletedPayment />}
          />
          <Route path="shopper-detail/:id" element={<ShopperDetail />} />
          <Route
            path="shopper-promotion-detail/:id"
            element={<ShopperPromotionDetail />}
          />
        </Route>

        <Route path="drivers" element={<Drivers />}>
          <Route index element={<DriverList />} />
          <Route path="driver-list" element={<DriverList />} />
          <Route path="withdrawal" element={<DriverWithdrawal />} />
          <Route path="remit" element={<DriverRemit />} />
          <Route path="payment" element={<DriverPayment />} />
          <Route path="promotion" element={<DriverPromotion />} />
          <Route
            path="driver-promotion-detail/:id"
            element={<DriverPromotionDetail />}
          />
          <Route
            path="completed-payment"
            element={<DriverCompletedPayment />}
          />
          <Route path="driver-detail/:id" element={<DriverDetail />} />
          <Route path="deletion-request" element={<DriverDeletionRequest />} />
          <Route path="driver-push-orders" element={<DriverPushOrders />} />
          <Route
            path="driver-push-orders/:id"
            element={<DriverPushOrderDetail />}
          />
          <Route
            path="deletion-request/:id"
            element={<DriverDeletionRequestDetail />}
          />
        </Route>

        <Route path="bank-payment" element={<BankPayment />}>
          <Route index element={<CartShopping />} />
          <Route path="cart-shopping" element={<CartShopping />} />
          <Route
            path="cart-shopping-order-detail/:id"
            element={<CartShoppingOrderDetail />}
          />
           <Route
            path="cart-shopping-user-detail/:id"
            element={<CartShoppingUserDetail />}
          />
          <Route
            path="personalize-shopping"
            element={<PersonalizeShopping />}
          />
          <Route
            path="personalize-shopping-order-detail/:id"
            element={<PersonalizeShoppingOrderDetail />}
          />
          <Route path="fuel-gas" element={<FuelGas />} />
          <Route
            path="fuel-gas-order-detail/:id"
            element={<FuelGasOrderDetail />}
          />
          <Route path="wallet-funding" element={<WalletFunding />} />
          <Route
            path="wallet-funding-order-detail/:id"
            element={<WalletFundingOrderDetail />}
          />
          <Route path="laundry" element={<LaundryPay />} />
          <Route path="requested-transfer" element={<RequestedTransfer />} />
          <Route
            path="requested-transfer-user-detail/:id"
            element={<RequestedTransferUserDetail />}
          />
          <Route
            path="laundry-order-detail/:id"
            element={<LaundryPayOrdersDetail />}
          />
          <Route
            path="completed-bank-transfer"
            element={<CompletedBankTransfer />}
          />
        </Route>

        <Route path="user" element={<Users />}>
          <Route index element={<UsersList />} />

          <Route path="user-list" element={<UsersList />} />
          <Route path="user-birthday" element={<UserBirthdays />} />
          <Route path="user-referal-rankings" element={<UserReferalRankings />} />
          <Route path="user-referal-rankings/:id" element={<UserReferalRankingDetail />} />
          <Route path="email-marketing" element={<UserEmailMarketing />} />
          <Route path="auto-email-message" element={<UserAutoEmailMessage />} />
          <Route
            path="email-marketing/:id"
            element={<UserEmailMarketingDetail />}
          />
          <Route path="user-transcation" element={<UserTranscations />} />
          <Route path="vtu-transcation" element={<VTUTranscations />} />
          <Route
            path="user-transcation/:id"
            element={<UserTranscationsDetail />}
          />
          <Route
            path="vtu-transcation/:id"
            element={<VTUTranscationsDetail />}
          />
          <Route path="user-detail/:id" element={<UserDetail />} />
          <Route path="to-up-wallets" element={<UserTopupWallet />} />
          <Route path="wallet-deduction" element={<UserWalletDeduction />} />
          <Route
            path="wallet-deduction/:id"
            element={<UserWalletDeductionDetail />}
          />
          <Route path="shopping-coupon" element={<UserShoppingCoupon />} />
          <Route path="laundry-coupon" element={<UserLaundryCoupon />} />
          <Route path="isusu-coupon" element={<UserIsusuCoupon />} />
          <Route path="to-up-wallets/:id" element={<UserTopupWalletDetail />} />
          <Route path="promotions" element={<UserPromotion />} />
          <Route path="promotions/:id" element={<UserPromotionsDetail />} />
          <Route path="deletion-request" element={<UsersDeletionRequest />} />
          <Route
            path="deletion-request/:id"
            element={<UsersDeletionRequestDetail />}
          />
          <Route path="tippers" element={<Tipper />} />
          <Route path="daily-roi" element={<DailyROI />} />
          <Route
            path="tippers-order-detail/:id"
            element={<TipperOrderDetails />}
          />
          <Route path="coupon-history" element={<UserCouponHistory />} />
          <Route path="promotion-history" element={<UserPromotionHistory />} />
        </Route>

        <Route path="fuel" element={<Fuel />}>
          <Route index element={<FuelList />} />
        </Route>
        <Route path="legal" element={<Legal />}>
          <Route index element={<TermandCondition />} />
          <Route path="terms-and-conditions" element={<TermandCondition />} />
          <Route path="privacy-and-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="monitor" element={<MonitorOrder />}>
          <Route index element={<MonitorOrders />} />
        </Route>

        <Route path="transport" element={<Transport />}>
          <Route index element={<TransportList />} />
        </Route>

        <Route path="location" element={<Location />}>
          <Route index element={<Countries />} />
          <Route path="countries" element={<Countries />} />
          <Route path="states" element={<States />} />
          <Route path="province" element={<Province />} />
        </Route>

        <Route path="investment" element={<Investment />}>
          <Route index element={<InvestmentList />} />
          <Route path="investments" element={<InvestmentList />} />
          <Route path="add-investment" element={<AddInvestment />} />
          <Route path="investor" element={<InvestorList />} />
          <Route path="project" element={<ProjectList />} />
          <Route path="isusu" element={<IsusuList />} />
          <Route
            path="isusu-approval-request"
            element={<IsusuApprovalRequest />}
          />
          <Route
            path="isusu-approval-user-detail/:id"
            element={<IsusuApprovalUserDetail />}
          />
          <Route path="isusu-defaulter" element={<IsusuDefaulter />} />
          <Route path="isusu-receivers" element={<IsusuReceivers />} />
          <Route path="isusu-details/:id" element={<IsusuDetails />} />
        </Route>

        <Route path="admin" element={<Admin />}>
          <Route index element={<AdminList />} />
          <Route path="admin" element={<AdminList />} />
          <Route path="admin-detail/:id" element={<AdminDetail />} />

          <Route path="admin-roles" element={<AdminRole />} />
          <Route path="assign-admin-functions" element={<AdminFunctions />} />
          <Route path="new-admin" element={<NewAdmin />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
